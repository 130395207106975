import Body from './Body';
import Header from './Header';
import Background from './assets/background.svg';

const App = () => {
  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="absolute max-w-3xl z-[-100]">
        <img src={Background} />
      </div>
      <div className="flex flex-col bg-white shadow-3xl m-4 w-full max-w-xl">
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default App;
