const Header = () => {
  return (
    <div className="w-full flex justify-between p-4 items-start">
      <div className="flex gap-4 items-center">
        <div className="flex text-[24px] font-semibold gap-1">
          <span className="text-ak-red">A</span>
          <span className="text-ak-green">L</span>
          <span className="text-ak-blue">E</span>
          <span className="text-ak-red">X</span>
        </div>
        <div className="text-[12px]">Founder & Engineer</div>
      </div>
      <div className="flex flex-col items-end text-[16px]">
        <a
          href="https://linkedin.com/in/alexekoren"
          className="font-semibold text-[#0077B5]"
        >
          LinkedIn
        </a>
        <a
          href="https://twitter.com/alexekoren"
          className="font-semibold text-[#1DA1F2]"
        >
          Twitter
        </a>
      </div>
    </div>
  );
};

export default Header;
