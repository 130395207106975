import Geometry from './assets/geometry.svg';

const Body = () => {
  return (
    <div className="flex flex-col md:flex-row p-4">
      <div className="md:w-1/2">
        <div className="text-[48px] text-ak-red font-bold">Hello!</div>
        <div className="text-[24px] font-semibold">
          I'm Alex Koren, a founder focused on early stage prototyping in B2C
          and B2B SaaS.
        </div>
      </div>
      <div className="md:w-1/2 p-8 max-h-full">
        <img src={Geometry} />
      </div>
    </div>
  );
};

export default Body;
